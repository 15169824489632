/**
 * Const with params element
 * Block Feature component
 * Display a block of feature with one icon in vertical layout
 */
/* Import section */
import React from "react"
import Iconcontactsecure from "../images/icons/iconcontactsecure.inline.svg"
import Iconlab from "../images/icons/iconlab.inline.svg"
import Iconorganize from "../images/icons/iconorganize.inline.svg"
import Iconrenewprescription from "../images/icons/iconrenewprescription.inline.svg"
import Iconschedule from "../images/icons/iconschedule.inline.svg"
import Iconvisitnote from "../images/icons/iconvisitnote.inline.svg"
import "../styles/components/blockfeature.scss"

/* Declaration function */
const Blockfeature= ({ titleFeature, textIcon }) => {
  return (
    <div className="block-feature">
      <div className="icon-feature">
        {textIcon === "secure" && <span><Iconcontactsecure className="svg-icon-feature"/></span>}
        {textIcon === "lab" && <span><Iconlab className="svg-icon-feature"/></span>}
        {textIcon === "organize" && <span><Iconorganize className="svg-icon-feature"/></span>}
        {textIcon === "renew" && <span><Iconrenewprescription className="svg-icon-feature"/></span>}
        {textIcon === "schedule" && <span><Iconschedule className="svg-icon-feature"/></span>}
        {textIcon === "visit" && <span><Iconvisitnote className="svg-icon-feature"/></span>}
      </div>
      <p dangerouslySetInnerHTML={{ __html:titleFeature }} />
    </div>
  )
}

/* Export function */
export default Blockfeature
