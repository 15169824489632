/**
 * Const with params element
 * Block Step component
 * Display a block of step with one icon in vertical layout
 */
/* Import section */
import React from "react"
import Iconvcacessmedicalrecord from "../images/icons/iconvcacessmedicalrecord.inline.svg"
import Iconvcbookappointment from "../images/icons/iconvcbookappointment.inline.svg"
import Iconvcmatchdoctor from "../images/icons/iconvcmatchdoctor.inline.svg"
import Iconvcmatchdoctor2 from "../images/icons/iconvcmatchdoctor2.inline.svg"
import Iconvcplans from "../images/icons/iconvcplans.inline.svg"
import Iconvcsignup from "../images/icons/iconvcsignup.inline.svg"
import Iconvcsharing from "../images/icons/iconvcsharing.inline.svg"
import "../styles/components/blockstep.scss"

/* Declaration function */
const Blockstep= ({ titleStep, subtitleStep, textStep, textIcon }) => {
  return (
    <div className="block-step">
      <div className="icon-step">
        {textIcon === "access" && <span><Iconvcacessmedicalrecord className="svg-icon-feature"/></span>}
        {textIcon === "book" && <span><Iconvcbookappointment className="svg-icon-feature"/></span>}
        {textIcon === "match" && <span><Iconvcmatchdoctor className="svg-icon-feature"/></span>}
        {textIcon === "match2" && <span><Iconvcmatchdoctor2 className="svg-icon-feature"/></span>}
        {textIcon === "plans" && <span><Iconvcplans className="svg-icon-feature"/></span>}
        {textIcon === "signup" && <span><Iconvcsignup className="svg-icon-feature"/></span>}
        {textIcon === "sharing" && <span><Iconvcsharing className="svg-icon-feature"/></span>}
      </div>
      <div className="text-step">
        <p dangerouslySetInnerHTML={{ __html:titleStep }} />
        {subtitleStep && <p className="subtitle" dangerouslySetInnerHTML={{ __html:subtitleStep }} />}
        {textStep && <p className="subtext" dangerouslySetInnerHTML={{ __html:textStep }} />}
      </div>
    </div>
  )
}

/* Export function */
export default Blockstep
