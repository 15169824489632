/**
 * Const with params element
 * Banner Last Article component
 * Display a banner of the latest article
 */
/* Import section */
import { Link } from "gatsby"
import React from "react"
import Ctabutton from "./ctabutton"
import "../styles/components/bannerlastarticle.scss"

/* Declaration function */
const Bannerlastarticle = ({ titleArticle, slugArticle}) => {
  return(
    <section className="section-bannerblog">
      <div className="banner-title">
        <h2>Latest Blog</h2>
      </div>
      <div className="banner-content">
        <div className="box-banner">
          <h3><Link to={`/blog/${slugArticle}/`}>{titleArticle}</Link></h3>
          <div className="button-readmore">
            <Ctabutton ctaLabel="Read The Article" ctaLink={`/blog/${slugArticle}`} otherStyle="white-blue-normal-format" />
          </div>
        </div>
      </div>
    </section>
  )
}

/* Export function */
export default Bannerlastarticle

