/**
 * Homepage page
 */
/* Import section */
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Bannerlastarticle from "../components/bannerlastarticle"
import Bannervirtualcare from "../components/bannervirtualcare"
import Blockfeature from "../components/blockfeature"
import Blockstep from "../components/blockstep"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import Shapedoubleleaf from "../images/shapes/shapedoubleleaf.inline.svg"
import "../styles/pages/home.scss"

/* Page function declaration */
const IndexPage = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageHome

  //CTA Footer page Speak to a Provider
  var ctaFooterSpeak= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "speak"
  })

  return (
    <Layout className="homepage">
       <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        datastructured="intro"
      />

      {/* Section Hero */}
      <Hero classNamePage="home" colorHero="green-light" vcPromo={dataAcf.promoHomepage} vcPlans="cta-plans" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralHomepage} contentH1={dataAcf.contentGeneralHomepage} classNameH1="home green-light" vc="true" />

     {/* Section Banner Last Article */}
      <Bannerlastarticle
        titleArticle={data.lastArticle.edges[0].node.title}
        slugArticle={data.lastArticle.edges[0].node.slug}
      />

      {/* Section Virtual Care -- 07-11-23 hided by mc */}
      {/* <section className="section-virtualcare">
        <div className="content-virtualcare">
          <div className="shape-doubleleaf">
            <Shapedoubleleaf />
          </div>
          <div className="title-virtualcare">
            <StaticImage  src="../images/icons/virtual_care_blue_icon.png" alt="Icon leaf blue Virtual Care PortalConnect" layout="fixed" width={36} quality={100} />
            <h2 className="title-level-1">{dataAcf.titleVcHomepage}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html:dataAcf.contentVcHomepage}} />
          <div className="list-steps">
            <h3>{dataAcf.titleStepsVcHomepage}</h3>
            <div className="wrap-list-steps">
              {dataAcf.repeaterStepsVcHomepage.map((step, index) => (
                <div key={index}>
                  <Blockstep key={ `step-${index}` } titleStep={step.titleStepVcHomepage} subtitleStep={step.subtitleStepVcHomepage} textIcon={step.iconStepVcHomepage}/>
                  {index !== dataAcf.repeaterStepsVcHomepage.length-1 && <div className="shape-nextstep"></div>}
                </div>
                )
              )}
            </div>
          </div>
        </div>
        <Bannervirtualcare idCta="virtualcare-homepage" contentBanner={dataAcf.bannerTextVcHomepage} ctaLabelBanner={dataAcf.bannerCtaVcHomepage?.title} ctaLinkBanner={dataAcf.bannerCtaVcHomepage?.url} />
      </section> */}

      {/* Section List features */}
      <section className="section-list-features">
        <div className="main-section green">
          <div className="content-general ">
            <h2 className="title-level-1">{dataAcf.titleListFeatures}</h2>
            <div className="list-features flex-content">
              {dataAcf.repeaterFeatures.map((feature, i) => (
                  <Blockfeature key={ `feature-${i}` } titleFeature={feature.titleFeature} textIcon={feature.iconFeature}/>
                )
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Section CtaFooter  --  07-11-23 hided by mc**/}
      {/* {ctaFooterSpeak.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="speak" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="green" />
      })} */}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "homepage"}) {
      acfPageHome {
        titleGeneralHomepage
        contentGeneralHomepage
        titleListFeatures
        repeaterFeatures {
          titleFeature
          iconFeature
        }
        promoHomepage
        titleVcHomepage
        contentVcHomepage
        titleStepsVcHomepage
        repeaterStepsVcHomepage {
          iconStepVcHomepage
          titleStepVcHomepage
          subtitleStepVcHomepage
        }
        bannerTextVcHomepage
        bannerCtaVcHomepage{
          title
          url
        }
      }
      seo {
        metaDesc
        title
      }
    }
    lastArticle: allWpPost(limit: 1, sort: {fields: [date], order:DESC}) {
      edges {
        node {
          title
          slug
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        videoAbout
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
/* Export page informations */
export default IndexPage
